import React from "react";
import { API_HOSTNAME } from "../recoil/applicationData";
import Application from "./Application";
import './style.scss';
import Login from "./login";
import ReCog from '@praetorian-studio/recog';
import axios from 'axios';
import CognitoChallenge from "./login/CognitoChallenge";
import ResetPassword from "./login/ResetPassword";

export default function BioHarmony(props: {}) {
	const [Recog, status] = ReCog.useRecog();

	function login(username: string, password: string) {
		Recog.login(username, password).then(() => {
			axios.post(`${API_HOSTNAME}/v1/auth`, {
			}, {
				headers: {
					'Authorization': `Bearer ${Recog.idToken}`
				}

			})
		});
	}

	return <>
		<div style={{ display: 'none' }}>{status}</div>

		<ReCog.LoggedIn>
			<Application />
		</ReCog.LoggedIn>

		<ReCog.Unauthorized>
			<ReCog.BadAuth>
				<h3>Incorrect Login</h3>
			</ReCog.BadAuth>
			<Login auth={login} />
		</ReCog.Unauthorized>

		<ReCog.Validating>
			<h3>Login Validation</h3>
		</ReCog.Validating>

		<ReCog.NewPasswordRequired>
			<CognitoChallenge />
		</ReCog.NewPasswordRequired>
	</>

}
